<template>
  <div class="widgetContainer bg-white contact-account-form">
    <div class="navigationHeader">
      <div class="navigationHeader__inner">
        <span class="icon-Arrow-big-left back-action" @click="cancel" />
        <p class="title">
          {{
            $t(
              getCreateContactData && getCreateContactData.id
                ? 'contact_details_EditTitle'
                : 'contact_Details_NavTitle'
            )
          }}
        </p>
        <span class="el-icon-close" @click="drawerClose" />
      </div>
    </div>

    <div class="widgetContainer__body">
      <el-form
        class="wise-form"
        ref="contactDetail"
        :model="formData"
        :rules="formRules">
        <p class="wise-form__header">
          {{ getCreateContactData.selectedAccountType }}
          {{ $t('contact_Account_Title') }}
        </p>
        <el-form-item
          class="is-no-asterisk"
          prop="accountNumber"
          :label="$t('contact_Account_AccNo')">
          <!-- <el-input v-model="formData.accountNumber"></el-input> -->
          <AccountNumber v-model="formData.accountNumber" />
        </el-form-item>
        <el-form-item
          v-if="getCreateContactData.selectedAccountType === 'ach'"
          class="is-no-asterisk"
          prop="routingNumber"
          :label="$t('contact_Account_RoutingNo')">
          <!-- <el-input v-model="formData.routingNumber"></el-input> -->
          <RouteNumber v-model="formData.routingNumber" />
        </el-form-item>
        <el-form-item
          v-if="getCreateContactData.selectedAccountType === 'ach'"
          class="is-no-asterisk"
          prop="accountType"
          :label="$t('contact_Account_Type')">
          <el-select v-model="formData.accountType" style="width: 100%">
            <el-option label="Personal Savings" value="personalSavings">
              Personal Savings
            </el-option>
            <el-option label="Personal Checking" value="personalChecking">
              Personal Checking
            </el-option>
            <el-option label="Business Savings" value="businessSavings">
              Business Savings
            </el-option>
            <el-option label="Business Checking" value="businessChecking">
              Business Checking
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="getCreateContactData.selectedAccountType === 'ach'"
          class="is-no-asterisk"
          prop="bankName"
          :label="$t('contact_Account_Bank')">
          <el-input v-model="formData.bankName" />
        </el-form-item>
      </el-form>
    </div>
    <div class="el-drawer__footer">
      <el-button
        type="primary"
        :disabled="!isFormValid"
        @click="next()"
        class="width-100 el-button__brand brand">
        {{ $t('done') }}
      </el-button>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapGetters, mapActions } from 'vuex';
import ContactMixin from '../mixins/contact';
import AccountNumber from '@/components/AccountNumber';
import RouteNumber from '@/components/RouteNumber';
export default {
  name: 'ContactAccountForm',
  mixins: [ContactMixin],
  components: {
    AccountNumber,
    RouteNumber
  },
  data() {
    return {
      formData: {
        accountNumber: '',
        routingNumber: '',
        accountType: '',
        bankName: ''
      },
      formRules: {
        accountNumber: [
          {
            required: true,
            message: this.$t('accountNumberRequired'),
            trigger: 'blur'
          },
          {
            pattern: /^\d{6,17}$/,
            message: this.$t('accountNumberNotValid'),
            trigger: 'blur'
          }
        ],
        routingNumber: [
          {
            required: true,
            message: this.$t('routingNumberRequired'),
            trigger: 'blur'
          },
          {
            pattern: /^\d{9}$/,
            message: this.$t('routingNumberNotValid'),
            trigger: 'blur'
          }
        ],
        accountType: [
          {
            required: true,
            message: this.$t('accountTypeRequired'),
            trigger: 'blur'
          }
        ],
        bankName: [
          {
            required: true,
            message: this.$t('bankNameRequired'),
            trigger: 'blur'
          }
        ]
      }
    };
  },
  created() {
    if (!this.getCreateContactData) {
      this.$router.replace('/contact/detailForm');
    } else {
      if (this.getCreateContactData.selectedAccountType === 'intrabank') {
        this.formRules = {
          accountNumber: [
            {
              required: true,
              message: this.$t('accountNumberRequired'),
              trigger: 'blur'
            },
            {
              pattern: /^\d{6,17}$/,
              message: this.$t('accountNumberNotValid'),
              trigger: 'blur'
            }
          ]
        };
        this.formData = {
          accountNumber: ''
        };

        if (this.getCreateContactData.intrabank) {
          this.formData = {
            accountNumber: this.getCreateContactData.intrabank.accountNumber
          };
        }
      } else {
        this.formData = {
          accountNumber: '',
          routingNumber: '',
          accountType: '',
          bankName: ''
        };

        if (this.getCreateContactData.ach) {
          this.formData = {
            accountNumber: this.getCreateContactData.ach.accountNumber,
            routingNumber: this.getCreateContactData.ach.routingNumber,
            accountType: this.getCreateContactData.ach.accountType,
            bankName: this.getCreateContactData.ach.bankName
          };
        }
      }
    }
  },
  computed: {
    ...mapGetters('contact', ['getCreateContactData']),
    isFormValid() {
      if (this.getCreateContactData.selectedAccountType === 'intrabank') {
        return (
          this.formData.accountNumber &&
          this.formData.accountNumber !== '' &&
          this.formData.accountNumber.length >= 6 &&
          this.formData.accountNumber.length <= 17
        );
      } else if (this.getCreateContactData.selectedAccountType === 'ach') {
        if (
          this.formData.accountNumber &&
          this.formData.accountNumber !== '' &&
          this.formData.routingNumber !== '' &&
          this.formData.accountType !== '' &&
          this.formData.bankName !== '' &&
          this.formData.accountType !== '' &&
          this.formData.accountNumber.length >= 6 &&
          this.formData.accountNumber.length <= 17 &&
          this.formData.routingNumber.length === 9
        ) {
          return true;
        }
      }
      return false;
    }
  },
  methods: {
    ...mapMutations('contact', [
      'updateCreateContactData',
      'updateSelectedContactData'
    ]),
    ...mapActions('contact', ['createContact', 'updateContact']),
    next() {
      this.$refs.contactDetail.validate((valid) => {
        if (!valid) return;

        let action = 'createContact';
        if (this.getCreateContactData.id) {
          action = 'updateContact';
        }
        const { selectedAccountType, ...otheData } = this.getCreateContactData;

        const loader = this.showLoader();
        this[action]({
          ...otheData,
          accountId: this.getSelectedAccount.id,
          [selectedAccountType]: this.formData
        })
          .then((contact) => {
            this.updateSelectedContactData(contact);
            const {
              id,
              accountId,
              address,
              ach,
              intrabank,
              firstName,
              email,
              phone
            } = contact;
            this.updateCreateContactData({
              id,
              accountId,
              address,
              ach,
              intrabank,
              firstName,
              email,
              phone
            });
            setTimeout(() => {
              this.$router.replace('/contact/accountTypeForm');
            }, 0);
          })
          .catch((e) => {
            console.log(e);
            this.apiErrorMessage(e);
          })
          .finally(() => {
            loader.close();
          });
      });
    },
    cancel() {
      this.$router.replace('/contact/accountTypeForm');
    }
  }
};
</script>
<style lang="scss">
.contact-account-form {
  .wise-form__header {
    text-transform: capitalize;
    margin-bottom: 10px;
  }
  .submit.disabled {
    color: gray;
  }
}
</style>
